import type {
  NextFunction as _NextFunction,
  MiddleWareFunction as _MiddleWareFunction,
  RequestGet as _RequestGet,
  RequestPost as _RequestPost,
  Response as _Response,
} from './async'

import async from './async'

export type NextFunction = _NextFunction
export type MiddleWareFunction = _MiddleWareFunction
export type RequestGet = _RequestGet
export type RequestPost = _RequestPost
export type Response = _Response

export default async
