/* eslint-disable @typescript-eslint/no-explicit-any */

export function parseUrl(url: string, config: any = {}): string {
  const params = config.params
  let regex: RegExp
  if (params) {
    Object.entries(params).forEach(([key, value]: [string, any]) => {
      regex = new RegExp(`:${key}`)
      if (regex.test(url)) {
        url = url.replace(`:${key}`, value)
        delete config.params[key]
      }
    })
  }

  return url
}
