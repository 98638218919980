import { AxiosError } from 'axios'
import { UseToastOptions } from '@chakra-ui/react'

export const DEFAULT_TOAST_OPTIONS: UseToastOptions = {
  status: 'success',
  isClosable: true,
  position: 'top-right',
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function getErrorStrings(errors?: any): string[] {
  let errorsArray = errors?.response?.data?.errors

  if (errorsArray && !Array.isArray(errorsArray))
    errorsArray = errorsArray.full_messages

  if (!errorsArray || errorsArray.lenght <= 0) return ['พบข้อผิดพลาด']

  return errorsArray
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getErrorToastObject(errors: AxiosError<any>): UseToastOptions {
  return {
    ...DEFAULT_TOAST_OPTIONS,
    description: getErrorStrings(errors).join(', '),
    status: 'error',
  }
}
