/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import axios, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios'
import { parseUrl } from './helpers'

export enum Methods {
  get = 'get',
  post = 'post',
  put = 'put',
  patch = 'patch',
  delete = 'delete',
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Response = AxiosResponse<any>
export type NextFunction = () => Promise<AxiosResponse>
export type MiddleWareFunction = (
  config: AxiosRequestConfig,
  next: NextFunction,
) => Promise<AxiosResponse>
export type RequestGet = (
  url: string,
  config: AxiosRequestConfig,
  ...args: MiddleWareFunction[]
) => Promise<AxiosResponse>
export type RequestPost = (
  url: string,
  data: any,
  config: AxiosRequestConfig,
  ...args: MiddleWareFunction[]
) => Promise<AxiosResponse>
export type Interface = (
  method: Methods,
  url: string,
  config: AxiosRequestConfig,
  ...args: MiddleWareFunction[]
) => Promise<AxiosResponse>

function errorHandler(_: AxiosError) {
  // if (error.response) {
  //   console.error(
  //     `${error.response.status}: ${error.response.data.description}`,
  //   )
  // } else if (typeof error === 'string' || typeof error === 'number') {
  //   console.error(`ERROR: ${error}`)
  // }
}

async function get(
  url: string,
  config: AxiosRequestConfig,
  ...args: MiddleWareFunction[]
): Promise<AxiosResponse> {
  try {
    switch (args.length) {
      case 0:
        url = parseUrl(url, config)
        return await axios.get(url, config)
      case 1:
      default:
        return args[0](
          config,
          async () => await get(url, config, ...args.slice(1)),
        )
    }
  } catch (error: any) {
    errorHandler(error)

    throw error
  }
}

async function post(
  url: string,
  data: any,
  config: AxiosRequestConfig,
  ...args: MiddleWareFunction[]
): Promise<AxiosResponse> {
  try {
    switch (args.length) {
      case 0:
        url = parseUrl(url, config)
        return await axios.post(url, data, config)
      case 1:
      default:
        return args[0](
          config,
          async () => await post(url, data, config, ...args.slice(1)),
        )
    }
  } catch (error: any) {
    errorHandler(error)

    throw error
  }
}

async function put(
  url: string,
  data: any,
  config: AxiosRequestConfig,
  ...args: MiddleWareFunction[]
): Promise<AxiosResponse> {
  try {
    switch (args.length) {
      case 0:
        url = parseUrl(url, config)
        return await axios.put(url, data, config)
      case 1:
      default:
        return args[0](
          config,
          async () => await put(url, data, config, ...args.slice(1)),
        )
    }
  } catch (error: any) {
    errorHandler(error)

    throw error
  }
}

async function patch(
  url: string,
  data: any,
  config: AxiosRequestConfig,
  ...args: MiddleWareFunction[]
): Promise<AxiosResponse> {
  try {
    switch (args.length) {
      case 0:
        url = parseUrl(url, config)
        return await axios.patch(url, data, config)
      case 1:
      default:
        return args[0](
          config,
          async () => await patch(url, data, config, ...args.slice(1)),
        )
    }
  } catch (error: any) {
    errorHandler(error)

    throw error
  }
}

async function remove(
  url: string,
  config: AxiosRequestConfig,
  ...args: MiddleWareFunction[]
): Promise<AxiosResponse> {
  try {
    switch (args.length) {
      case 0:
        url = parseUrl(url, config)
        return await axios.delete(url, config)
      case 1:
      default:
        return args[0](
          config,
          async () => await remove(url, config, ...args.slice(1)),
        )
    }
  } catch (error: any) {
    errorHandler(error)

    throw error
  }
}

async function requests(
  method: Methods | string,
  url: string,
  config: AxiosRequestConfig,
  ...args: MiddleWareFunction[]
): Promise<AxiosResponse> {
  switch (method) {
    case Methods.post:
      return post(url, config.data, config, ...args)
    case Methods.put:
      return put(url, config.data, config, ...args)
    case Methods.patch:
      return patch(url, config.data, config, ...args)
    case Methods.delete:
      return remove(url, config, ...args)
    case Methods.get:
    default:
      return get(url, config, ...args)
  }
}

export default {
  requests,
  get,
  post,
  put,
  patch,
  delete: remove,
}
